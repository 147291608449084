import { MaximumQuantityInputError } from '../errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from '../errors/MinimumQuantityInputError';
import { QuantityInputError } from '../errors/QuantityInputError';

const minimumQuantity = 1;
const maximumQuantity = 9999999;
const influenzaMaxQuantity = 9999999;
const base = 10;
const zero = 0;
const plus = 1;
const minus = 2;
const one = 1;

const DISABLED_LABEL = 'disabled';
const DISABLED_IMG = 'disabled-img';
export class ProductUtils {
  // Custom-Prpduct Refinement Category Formatting
  static categoryFormatting(data): any {
    const value = [];
    if (data.id === one || data.id === one.toString()) {
      value.push({ name: 'All', value: data.url });
    }
    data.subcategories.forEach((obj) => {
      value.push({ name: obj.name, value: obj.url });
    });
    return value;
  }

  static alterQuantity(
    operator,
    idData,
    stepQuantity,
    minQuantity,
    maxQuantity,
    page,
    numberOfBoxes?
  ): any {
    if (stepQuantity === undefined) {
      stepQuantity = one;
    }
    if (minQuantity === undefined || page === 'category') {
      minQuantity = zero;
    }
    if (maxQuantity === undefined || maxQuantity > maximumQuantity) {
      maxQuantity = maximumQuantity;
    }

    if (!isNaN(stepQuantity)) {
      const inputElement = document.getElementById(
        `prodQuantity${idData}`
      ) as HTMLInputElement;
      const numInputs = document.querySelectorAll('input[type=number]');
      numInputs.forEach(function (input) {
        input.addEventListener('change', function (e) {
          if ((e.target as HTMLInputElement).value === '') {
            (e.target as HTMLInputElement).value = `${stepQuantity}`;
          }
        });
      });
      const minusIcon = document.getElementById(
        `minusIcon${idData}`
      ) as HTMLElement;

      const plusIcon = document.getElementById(
        `plusIcon${idData}`
      ) as HTMLElement;
      const noOfDoses = document.getElementById(
        `noOfDoses${idData}`
      ) as HTMLInputElement;
      const unitMinus = document.getElementById(`unitMinus${idData}`);
      const unitMinusDisable = document.getElementById(
        `unitMinusDisable${idData}`
      ) as HTMLElement;

      const unitPlus = document.getElementById(`unitPlus${idData}`);
      const unitPlusDisable = document.getElementById(
        `unitPlusDisable${idData}`
      ) as HTMLElement;
      let orderValue = parseInt(inputElement?.value, base);

      // inputElement value check
      const regex = new RegExp('^[0-9]{1,5}$', 'g');

      if (regex.test(inputElement?.value) === true) {
        // do the calculation
        if(noOfDoses){
          if (operator === plus) {
            orderValue += stepQuantity;
          } 
          else if (operator === minus && orderValue !== 0) {
            orderValue = orderValue - stepQuantity;
          } else if (operator === minus && orderValue === 0) {
            orderValue = orderValue - stepQuantity;
          }
          // Quantity change after calculation
          if (inputElement.value !== '') {
            inputElement.value = orderValue?.toString();
          }
        }
        else{if (operator === plus) {
          orderValue += stepQuantity;
        } 
        else if (operator === minus && orderValue !== 0) {
          orderValue = orderValue - stepQuantity;
        } else if (operator === minus && orderValue === 0) {
          orderValue = orderValue - stepQuantity;
        }
        // Quantity change after calculation
        if (inputElement.value !== '') {
          inputElement.value = orderValue?.toString();
        }}
        // validate min, max, step
        const quantityInputError = 'Quantity Input Error'
        if (orderValue % stepQuantity !== 0 && page === 'cart') {
          throw new QuantityInputError(quantityInputError);
        } else if (orderValue !== 0 && orderValue < minQuantity && page === 'cart') {
          throw new MinimumQuantityInputError(quantityInputError);
        } else if (orderValue > maxQuantity && page === 'cart') {
          throw new MaximumQuantityInputError(quantityInputError);
        }
        // icon chnages
        if (orderValue <= minQuantity) {
          minusIcon.classList.add(DISABLED_LABEL);
          unitMinus.classList.add(DISABLED_IMG);
          unitMinusDisable.classList.remove(DISABLED_IMG);
          plusIcon.classList.remove(DISABLED_LABEL);
          unitPlus.classList.remove(DISABLED_IMG);
          unitPlusDisable.classList.add(DISABLED_IMG);
          if (orderValue < 0) {
            orderValue = zero;
            inputElement.value = orderValue.toString();
          }
        } else if (orderValue >= maxQuantity) {
          minusIcon.classList.remove(DISABLED_LABEL);
          unitMinus.classList.remove(DISABLED_IMG);
          unitMinusDisable.classList.add(DISABLED_IMG);
          plusIcon.classList.add(DISABLED_LABEL);
          unitPlus.classList.add(DISABLED_IMG);
          unitPlusDisable.classList.remove(DISABLED_IMG);
        } else {
          minusIcon.classList.remove(DISABLED_LABEL);
          plusIcon.classList.remove(DISABLED_LABEL);
          unitPlus.classList.remove(DISABLED_IMG);
          unitMinus.classList.remove(DISABLED_IMG);
          unitPlusDisable.classList.add(DISABLED_IMG);
          unitMinusDisable.classList.add(DISABLED_IMG);
        }
       
        // noOfDoses Check
        if (inputElement.value !== '') {
          inputElement.value = orderValue.toString();
          const qty = Math.ceil(orderValue / stepQuantity)
          noOfDoses.innerHTML = `${qty} boîte(s)`
        } else {
          noOfDoses.innerHTML = `${stepQuantity} boîte(s)`;
        }
      } else {
        if (orderValue >= maxQuantity) {
          inputElement.value = maxQuantity.toString();
          plusIcon.classList.add(DISABLED_LABEL);
          unitPlus.classList.add(DISABLED_IMG);
          unitPlusDisable.classList.remove(DISABLED_IMG);
          if (!orderValue) {
            minusIcon?.classList.add(DISABLED_LABEL);
            unitMinus?.classList.add(DISABLED_IMG);
            unitMinusDisable?.classList.remove(DISABLED_IMG);
            plusIcon?.classList.remove(DISABLED_LABEL);
            unitPlus?.classList.remove(DISABLED_IMG);
            unitPlusDisable?.classList.add(DISABLED_IMG);
          }
        }
      }
    } else {
      return false;
    }
  }
}
